// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'qa',
  sso: {
    clientId: 'ids.builder',
    logoutUrl:  'https://sso-staging.ipplatform.com/ext/cloudlogout',
    authorizeUrl:  'https://sso-staging.ipplatform.com/as/authorization.oauth2',
    api: 'https://api.staging.ipplatform.com/sso/api',
  },
  core: {
    api: 'https://api.staging.ipplatform.com/core/api',
    home: 'https://staging.ipplatform.com'
  },
  origin: 'https://qa.idsbuilder.com',
  apiBaseUrl: 'https://api.qa.idsbuilder.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
